import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import 'rxjs';

@Injectable()

export class MessageService {

    private server_url = 'http://192.168.2.223/dist/server/test';

    constructor(private http: HttpClient) {}

    getAll(): Observable<any> {
        return this.http
            .get('/api/messages')
            .pipe(
                map((response: JSON) => {
                    return response;
                }),
                catchError(this.handleError<any>('Can`t Get Data'))
            );
    }

    gettest(): Observable<any> {
        return this.http
        .get(this.server_url)
        .pipe(
            map((response: JSON) => {
                console.log(response);
                return response;
            }),
            catchError(this.handleError<any>('Can`t Get Data'))
        );
    }

    regist(message: string): Observable<any> {
        return this.http
            .post('/api/messages', {message: message})
            .pipe(
            map((response: Response) => {
                const result = response.json();
                return result;
            }),
            catchError((error: Response) => Observable.throw(error.json()))
            );
    }
    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(operation + ' : ' + error);
            return of(result as T);
        };
    }
}

import { Component, OnInit, Renderer2 } from '@angular/core';
import { WebSocketService } from './service';
import { WebRTCService } from './service';
import { SubjectsService } from './service';
import { of } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {


  constructor(

  ) {}


  ngOnInit(): void {

  }


}



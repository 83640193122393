import { Injectable } from '@angular/core';

@Injectable()
export class SupportService {


    private mode = { video: false, audio: false, screen: false };
    private mediAPI = null;
    private mediaDevices = null;

    constructor() { }

    public getMode(): object {
        return this.mode;
    }

    public getMediAPI(): string {
        return this.mediAPI;
    }

    public getMediaDevices(): object {
        return this.mediaDevices;
    }

    public setDeviceSuppot(video, audio, screen): void {
        this.mode = { video : video, audio: audio, screen: screen };
    }

    public onVideo(): void {
        this.mode.video = true;
    }

    public onAudio(): void {
        this.mode.audio = true;
    }

    public offVideo(): void {
        this.mode.video = false;
    }

    public offAudio(): void {
        this.mode.audio = false;
    }

    /**
     * メディアデバイスAPIが利用可能か判定
     * APIが利用できない場合 mediaAPIにnullを代入
     */
    public checkScreenShare(): this {
        if (this.mediAPI !== null) {
            return this;
        }
        if (typeof (navigator.mediaDevices['getDisplayMedia']) === 'function') {
            console.log('Support Media API : getDisplayMedia');
            this.mediAPI = 'getDisplayMedia';

        } else if (typeof (navigator.mediaDevices['getUserMedia']) === 'function') {
            console.log('Support Media API : getUserMedia');
            this.mediAPI = 'getUserMedia';

        } else if (typeof (navigator['webkitGetUserMedia']) === 'function') {
            console.log('Support Media API : webkitGetUserMedia');
            this.mediAPI = 'webkitGetUserMedia';

        } else if (typeof (navigator['mozGetUserMedia']) === 'function') {
            console.log('Support Media API : mozGetUserMedia');
            this.mediAPI = 'mozGetUserMedia';

        } else {
            this.mediAPI = null;
        }
        return this;
    }

    /**
     * メディアデバイスAPIが利用可能な場合
     * 利用可能なデバイス一覧を返す
     */
    public async checkMediaDevice(): Promise<object> {
        return new Promise((resolve, reject) => {
            if (this.mediAPI !== null) {
                navigator.mediaDevices.enumerateDevices()
                .then((devices) => {
                        this.checkDevice(devices);
                        resolve(this.mode);
                    }).catch((error) => {
                        console.error('Not Devices');
                        reject(this.mode);
                    });
            } else {
                console.error('Not UserMedia API');
                reject(this.mode);
            }
        });
    }

    /**
     * 利用可能なデバイス一覧の取得
     */
    public listMediaDevices(): this {
        if (this.mediAPI !== null) {
            navigator.mediaDevices.enumerateDevices()
            .then((devices) => {
                this.checkDevice(devices);
                this.mediaDevices = devices;
            }).catch((error) => {
                console.error('Not Devices');
                this.mediaDevices = null;
            });
        } else {
            console.error('Not UserMedia API');
            this.mediaDevices = null;
        }
        return this;
    }

    /**
     * デバイス一覧から
     * オーディオ、ビデオがそれぞれ利用可能か判定
     */
    public checkDevice(devices): void {
        if (devices.length === 0) {
            this.mode.video = false;
            this.mode.audio = false;
        } else {
            for (const key in devices) {
                if (devices.hasOwnProperty(key)) {
                    if (devices[key]['kind'] === 'audioinput') {
                        this.mode.audio = true;
                    } else if (devices[key]['kind'] === 'videoinput') {
                        this.mode.video = true;
                    }
                }
            }
        }
    }




}
